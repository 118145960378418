//*************************************************************************************
//* WaMS Custom Validators
//* Version 1.0
//* Author: Damian Koch
//* JavaScript component of custom ComponentModel.DataAnnotations.ValidationAttribute
//* Adds client-side support of RequiredIf, and ValidYearDuration
//* Requires jQuery, jQuery.validate, jQuery.validate.unobtrusive
//*************************************************************************************
$(function () {
    jQuery.validator.addMethod('validyearduration', function (value, element, params) {
        if (value < 0) {
            return false;
        }
        return true;
    }, '');

    jQuery.validator.unobtrusive.adapters.add('validyearduration', function (options) {
        options.rules['validyearduration'] = {};
        options.messages['validyearduration'] = options.message;
    });

    jQuery.validator.addMethod('requiredif', function (value, element, parameters) {
        var name = '[name="' + parameters['dependentproperty'] + '"]';
        // get the target value (as a string, as that's what actual value will be)
        var targetvalue = parameters['targetvalue'];
        targetvalue = (targetvalue == null ? '' : targetvalue).toString();

        // get the actual value of the target control note - this probably needs to cater for more control types, e.g. radios
        var control = $(name, element.closest('form'));
        var controltype = control.attr('type');
        var actualvalue = controltype === 'checkbox' ? control.attr('checked').toString() : control.val();

        // if the condition is true, reuse the existing  required field validator functionality
        if (targetvalue === actualvalue) return $.validator.methods.required.call(this, value, element, parameters);

        return true;
    });

    jQuery.validator.unobtrusive.adapters.add('requiredif', ['dependentproperty', 'targetvalue'], function (options) {
        options.rules['requiredif'] = { dependentproperty: options.params['dependentproperty'], targetvalue: options.params['targetvalue'] };
        options.messages['requiredif'] = options.message;
    });

}(jQuery));

